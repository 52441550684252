import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { AuthService } from '@services/auth.service';
import { ContactFormFields } from '@services/contact.service';

@Component({
  selector: 'app-contact-us-dialog',
  templateUrl: './contact-us-dialog.component.html',
  styleUrls: ['./contact-us-dialog.component.scss']
})
export class ContactUsDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<ContactUsDialogComponent>,
    private auth: AuthService,
  ) { }

  handleCancel() {
    this.dialogRef.close();
  }

  handleFormSubmit(formFields: ContactFormFields) {
    this.dialogRef.close(formFields);
  }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { OwnerResolverService } from './resolvers/owner.resolver';
import { PolicyResolverService } from './resolvers/policy.resolver';
import { AuthGuard } from './services/auth.guard';

export const routes: Routes = [

  // Unauthenticated Routes
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'content',
    loadChildren: () => import('./pages/content/content.module').then(m => m.ContentModule)
  },
  {
    path: 'fast',
    loadChildren: () => import('./pages/home/bga/home.module').then(m => m.HomeModule)
  },
  {
    path: 'product-details',
    loadChildren: () => import('./pages/product-details/product-details.module').then(m => m.ProductDetailsModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
  },
  {
    path: 'signout',
    loadChildren: () => import('./pages/signout/signout.module').then(m => m.SignoutPageModule)
  },
  {
    path: 'callback',
    loadChildren: () => import('./pages/callback/callback.module').then(m => m.CallbackPageModule)
  },
  {
    path: 'onboarding/login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'onboarding/register',
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'onboarding/mfa',
    loadChildren: () => import('./pages/mfa/mfa.module').then(m => m.MfaModule)
  },
  {
    path: 'onboarding/getting-started',
    loadChildren: () => import('src/app/pages/onboarding/getting-started/getting-started.module')
      .then(m => m.GettingStartedPageModule),
  },
  {
    path: 'onboarding/verify-identity',
    loadChildren: () =>
      import('src/app/pages/onboarding/verify-identity/verify-identity.module')
        .then(m => m.OnboardingVerifyIdentityModule),
        resolve: {
          isPolicyEntityOwned: OwnerResolverService
        }
  },
  {
    path: 'view-application',
    loadChildren: () =>
      import('src/app/pages/declined-app-review/declined-app-review.module')
        .then(m => m.DeclinedAppReviewModule),
  },

  {
    path: 'session-expired',
    loadChildren: () => import('./pages/session-expired/session-expired.module').then(m => m.SessionExpiredModule)
  },

  /* Marketing Pages */
  {
    path: 'faq',
    loadChildren: () => import('src/app/pages/marketing/faq/faq.module').then(m => m.FaqModule)
  },
  {
    path: 'fast/faq',
    loadChildren: () => import('src/app/pages/marketing/bga/faq/faq.module').then(m => m.FaqModule)
  },
  {
    path: 'our-story',
    loadChildren: () => import('src/app/pages/marketing/our-story/our-story.module')
      .then(m => m.OurStoryModule),
  },
  {
    path: 'fast/our-story',
    loadChildren: () => import('src/app/pages/marketing/our-story/our-story.module')
      .then(m => m.OurStoryModule),
  },

  {
    path: 'fact-sheet',
    loadChildren: () => import('src/app/pages/marketing/fact-sheet/fact-sheet.module')
      .then(m => m.FactSheetModule),
  },
  {
    path: 'fast/fact-sheet',
    loadChildren: () => import('src/app/pages/marketing/bga/fact-sheet/fact-sheet.module')
      .then(m => m.FactSheetModule),
  },

  {
    path: 'contact-us',
    loadChildren: () => import('src/app/pages/marketing/contact-us/contact-us.module')
      .then(m => m.ContactUsModule),
  },
  {
    path: 'fast/contact-us',
    loadChildren: () => import('src/app/pages/marketing/bga/contact-us/contact-us.module')
      .then(m => m.ContactUsModule),
  },
  {
    path: 'terms-conditions',
    loadChildren: () => import('src/app/pages/legal/terms-conditions/terms-conditions.module')
      .then(m => m.TermsConditionsModule),
  },
  {
    path: 'fast/terms-conditions',
    loadChildren: () => import('src/app/pages/legal/terms-conditions/terms-conditions.module')
      .then(m => m.TermsConditionsModule),
  },
  {
    path: 'privacy',
    loadChildren: () => import('src/app/pages/legal/privacy/privacy.module')
      .then(m => m.PrivacyModule),
  },
  {
    path: 'fast/privacy',
    loadChildren: () => import('src/app/pages/legal/privacy/privacy.module')
      .then(m => m.PrivacyModule),
  },
  {
    path: 'security',
    loadChildren: () => import('src/app/pages/legal/security/security.module')
      .then(m => m.SecurityModule),
  },
  {
    path: 'fast/security',
    loadChildren: () => import('src/app/pages/legal/security/security.module')
      .then(m => m.SecurityModule),
  },
  {
    path: 'ccpa',
    loadChildren: () => import('src/app/pages/legal/ccpa/ccpa.module')
      .then(m => m.CcpaModule),
  },
  {
    path: 'fast/ccpa',
    loadChildren: () => import('src/app/pages/legal/ccpa/ccpa.module')
      .then(m => m.CcpaModule),
  },
  {
    path: 'legal-disclosures',
    loadChildren: () => import('src/app/pages/legal/legal-disclosures/legal-disclosures.module')
      .then(m => m.LegalDisclosuresModule),
  },
  {
    path: 'fast/legal-disclosures',
    loadChildren: () => import('src/app/pages/legal/legal-disclosures/legal-disclosures.module')
      .then(m => m.LegalDisclosuresModule),
  },
  {
    path: 'business-continuity',
    loadChildren: () => import('src/app/pages/legal/business-continuity/business-continuity.module')
      .then(m => m.BusinessContinuityModule),
  },
  {
    path: 'fast/business-continuity',
    loadChildren: () => import('src/app/pages/legal/business-continuity/business-continuity.module')
      .then(m => m.BusinessContinuityModule),
  },
  {
    path: 'article/:slug',
    loadChildren: () => import('./components/article-layout/article-layout.module')
      .then(m => m.ArticleLayoutModule),
  },
  // Authenticated Routes
  {
    path: 'plaid-callback',
    loadChildren: () => import('./pages/plaid-oauth-callback/plaid-oauth-callback.module').then(m => m.PlaidOauthCallbackModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'onboarding/contract/:policyId',
    loadChildren: () =>
      import('./pages/contract-assembly-loader/contract-assembly-loader.module')
        .then(m => m.ContractAssemblyLoaderModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'payment',
    loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentPageModule),
    canActivate: [AuthGuard]
  },

  // This route will be changed into the "policy selector" page when that is made.
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard-policies/dashboard-policies.module').then(m => m.DashboardPoliciesModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'dashboard/:policyId',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard],
    resolve: {
      policy: PolicyResolverService
    }
  },

  {
    // Temporarily renamed
    // These routes need to be split into the ones that require the AuthGuard and not
    path: 'onboarding',
    loadChildren: () => import('./pages/onboarding/onboarding.module').then(m => m.OnboardingPageModule),
    canActivate: [AuthGuard]
  },

  // Fallback 404 route (Unauthenticated)
  {
    path: '**',
    loadChildren: () => import('./pages/not-found/not-found.module').then(m => m.NotFoundModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const ONBOARDING_CODE_KEY = 'onboarding:code';
export const ONBOARDING_PAYMENT_METHOD_KEY = 'onboarding:paymentMethod';
export const ONBOARDING_PAYMENT_SCHEDULE_KEY = 'onboarding:paymentSchedule';
export const ONBOARDING_PAYMENT_INTENT_ID_KEY = 'onboarding:paymentIntentId';
export const ONBOARDING_CUSTOMER_ID_KEY = 'onboarding:customerId';
export const BACKSPACE_KEYBOARD_KEY = 'Backspace';
export const LINK_TOKEN_KEY = 'plaid:linktoken';
export const POLICY_ID_KEY = 'sw:policyId';
export const PLAID_OAUTH_REDIRECT_URI_KEY = 'plaid:oauthRedirectUri';
export const IS_ENTITY_OWNED = 'sw:isPolicyEntityOwned';

export const marketingPagesURLs = {
  factSheet: 'https://www.symetra.com/swifttermlife',
  faq: 'https://www.symetra.com/swifttermlife',
  ourStory: 'https://www.symetra.com/swifttermlife',
  contactUs: 'https://www.symetra.com/customer-service/how-can-we-help-you/email-us/swiftterm',
  privacy: 'https://www.symetra.com/admin-pages/privacy/',
  secondaryAddressee: 'https://www.symetra.com/our-products/individuals/life-insurance/term-life/swiftterm-life-insurance/secondary-addressee',
  secondaryAddresseeNY: 'https://www.symetra.com/our-products/individuals/life-insurance/term-life/swiftterm-life-insurance/secondary-addressee-ny',
  secondaryAddresseeNJ: 'https://www.symetra.com/our-products/individuals/life-insurance/term-life/swiftterm-life-insurance/secondary-addressee-nj',
  nonForfeitureNH: 'https://www.symetra.com/our-products/individuals/life-insurance/term-life/swiftterm-life-insurance/non-forfeiture-nh',
  nonForfeitureRI: 'https://www.symetra.com/our-products/individuals/life-insurance/term-life/swiftterm-life-insurance/non-forfeiture-ri',
  nonForfeitureWI: 'https://www.symetra.com/our-products/individuals/life-insurance/term-life/swiftterm-life-insurance/non-forfeiture-wi',
  security: 'https://www.symetra.com/admin-pages/security/',
  termsAndConditions: 'https://www.symetra.com/admin-pages/terms-conditions-symetra-online/',
  ccpa: 'https://www.symetra.com/admin-pages/ccpa/',
  legalDisclosures: 'https://www.symetra.com/admin-pages/legal-disclosures/',
  businessContinuity: 'https://www.symetra.com/admin-pages/business-continuity/'
}

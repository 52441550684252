import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ContentService } from '@services/content.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  footerData: any;
  showSocialLinks: boolean;
  routerSubscription: Subscription;
  buildId = environment.buildId;
  defaultFooterContent: any;
  bgaFooterContent: any;

  constructor(
    private contentService: ContentService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.defaultFooterContent = this.contentService.getData().footer;
    this.bgaFooterContent = this.contentService.getData().bgaFooter;
    this.footerData = this.defaultFooterContent;
    this.routerSubscription = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
    ).subscribe(this.handleNavigationEvent.bind(this));
  }

  ngOnDestroy() {
    this.routerSubscription?.unsubscribe();
  }

  handleNavigationEvent(routerEvent: NavigationEnd) {
    if (routerEvent.url.startsWith('/fast') && this.footerData !== this.bgaFooterContent) {
      this.footerData = this.bgaFooterContent;
    } else if (this.footerData !== this.defaultFooterContent) {
      this.footerData = this.defaultFooterContent;
    }
    if (routerEvent.url.includes('/onboarding')) {
      this.showSocialLinks = false;
    } else {
      this.showSocialLinks = true;
    }
  }
}

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BREAKPOINTS, FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MAT_SNACK_BAR_DATA, MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { SYASharedModule } from '@swiftcore/ui-shared';
import { GoogleTagManagerModule } from 'ngx-google-tag-manager';
import { NgxMaskModule } from 'ngx-mask';
import { QuillModule } from 'ngx-quill';
import Quill from 'quill';

import { SharedComponentsModule } from '@components/shared-components.module';
import { AppSpinnerModule } from '@components/spinner/spinner.module'
import { AuthGuard } from 'src/app/services/auth.guard';
import { NotificationsService } from 'src/app/services/notifications.service';
import HorizontalRule from 'src/app/utils/quill-hr';
import { LOCAL_STORAGE_TOKEN } from 'src/app/utils/tokens';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DEFAULT_BREAKPOINTS } from './breakpoints';
import { BackgroundHandlerComponent } from './components/background-handler/background-handler.component';
import { ChatBotComponent } from './components/chat-bot/chat-bot.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { ProfileDropdownComponent } from './components/profile-dropdown/profile-dropdown.component';
import { ToasterComponent } from './components/toaster/toaster.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { HttpErrorInterceptor } from './interceptors/error.interceptor';
import { CustomErrorHandler } from './services/custom-error-handler';

Quill.register({ 'formats/hr': HorizontalRule, }, true);

export const BreakPointsProvider = {
  provide: BREAKPOINTS,
  useValue: DEFAULT_BREAKPOINTS,
  multi: true
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ProfileDropdownComponent,
    ChatBotComponent,
    ToasterComponent,
    BackgroundHandlerComponent,
    FooterComponent
  ],
  imports: [
    AppSpinnerModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    FormsModule,
    MatSnackBarModule,
    MatButtonModule,
    SYASharedModule,
    ScullyLibModule,
    NgxMaskModule.forRoot(),
    QuillModule.forRoot({
      modules: {
        table: true
      }
    }),
    SharedComponentsModule,
    GoogleTagManagerModule.forRoot({
      id: environment.googleTagManagerID,
      gtm_auth: environment.googleTagManagerAuth,
      gtm_preview: environment.googleTagManagerEnv,
      gtm_cookies_win: 'x',
    })
  ],
  providers: [
    // Custom Breakpoints seem to be failing. Commenting for now.
    // BreakPointsProvider,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    {
      provide: MatDialogRef,
      useValue: {}
    },
    { provide: Window, useValue: window },
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    // Used for toast-like notifications and alerts
    NotificationsService,
    {
      provide: MAT_SNACK_BAR_DATA,
      useValue: {}
    },
    { provide: Document, useValue: document },
    Location,
    { provide: LOCAL_STORAGE_TOKEN, useValue: window.localStorage },
    AuthGuard,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

<div class="wrapper">
  <div class="header-container">
    <h1 class="page-title">Contact Us</h1>
    <p class="title-helper-text">Got a question? We're here to help.</p>

    <div class="contact-types" fxLayout="row">
      <div class="contact-type" >
        <div class="contact-type-content">
          <div class="contact-type-details">
            <a class="phone-number" href="tel:{{claimsCallToAction}}">{{claimsCallToAction}}</a>
            <p>Monday - Friday 
            <br> 6 a.m. to 4:30 p.m. PT | 9 a.m. to 7:30 p.m. ET</p>
          </div>
        </div>
        <div class="quantum-session-id">
          Session ID: {{quantumSessionId}}
          <span id="quantum-session-id-tooltip" class='help-icon'>
            <img src="assets/popup.svg" />
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="contact-form">
    <p class="form-header"><b>Send us a message</b></p>
    <form class="form-body" (submit)="handleFormSubmit()">
      <div *ngIf="!isAuthenticated || displayAllFields">
        <label class="form-field" data-pii>
          Full Name
          <input type="text" name="name" [(ngModel)]="name" required>
        </label>

        <label class="form-field" data-pii>
          Email
          <input type="email" name="email" [(ngModel)]="email" required>
        </label>

        <label class="form-field" data-pii>
          Phone number
          <input type="tel" name="phone" [(ngModel)]="phone" [mask]="'(000) 000-0000'" required>
        </label>
      </div>
      <label class="form-field">
        How can we help you?
        <textarea name="message" [(ngModel)]="message" rows="5" required></textarea>
      </label>
      
      <input type="hidden" name="country" [(ngModel)]="country" placeholder="Country">
      <input type="hidden" name="occupation" [(ngModel)]="occupation" placeholder="Occupation">

      <div>
        <button
          class="submit-button"
          [disabled]="!isValid()"
          mat-flat-button
        >
          Send message 
        </button>
      </div>
      <div class="california">
        If you are a California resident, please read our
        <a href="{{noticeAtCollectionLink}}" target="_blank">Notice at Collection</a>
        before you provide your information in order to understand what personal
        information we may collect from you, the purposes for collecting the
        information, and to whom we may disclose such information and for what
        reasons.
      </div>
    </form>
  </div>

</div>

import Quill from 'quill';

const Block = Quill.import('blots/block');

class HorizontalRule extends Block {
  static create(_value) {
    const node = super.create('hr');
    return node;
  }
}
HorizontalRule.blotName = 'hr';
HorizontalRule.tagName = 'hr';

export default HorizontalRule;

import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss']
})
export class ToasterComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private snarBarRef: MatSnackBarRef<ToasterComponent>
  ) { }

  dismissSnackBar() {
    this.snarBarRef.dismiss();
  }

}

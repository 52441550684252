import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-background-handler',
  templateUrl: './background-handler.component.html',
  styleUrls: ['./background-handler.component.scss']
})
export class BackgroundHandlerComponent implements OnInit, OnDestroy {

  // Setting this up for future state in case we want dynamic colors
  // This would require an inline SVG to be rendered instead of an url src
  // No current task/story for this - Suggestion per Chris
  solidColor = '#F5F6F7';
  primaryColor = '#007DD7';
  secondaryColor = '#035BAB';

  // This enables the gradient background to be disabled on a defined set of routes
  // We don't have any pages I'm aware of yet to use a flat BG, but the functionality works
  flatBg = false;
  flatBackgroundRoutes = [
    '/tbd',
    '/contract',
    '/onboarding/payment-error'
  ];

  routerSubscription: Subscription;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.routerSubscription = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd || e instanceof ActivationEnd),

    ).subscribe((routerEvent: NavigationEnd | ActivationEnd) => {
      // The ActivationEnd happens first to reset flatBg.
      // The NavigationEnd then checks if we are on a route we need to hide.
      if (routerEvent instanceof ActivationEnd) {
        this.flatBg = routerEvent.snapshot.routeConfig.path === '**';
      } else if (routerEvent instanceof NavigationEnd) {
        this.flatBackgroundRoutes.forEach((route: string) => {
          if (routerEvent.url.indexOf(route) !== -1) {
            this.flatBg = true;
          }
        });

        if (routerEvent.url === '/') {
          this.flatBg = true;
        }
      }
    });
  }

  // This would theoretically never live past the lifetime of the app itself,
  // but a best practice is to unsubscribe live subscriptions. (most observables besides http requests)
  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

}

import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Instance as TippyInstance } from 'tippy.js';

import { Customer } from '@services/api/policy.service';
import { ContactFormFields } from '@services/contact.service';
import { QuantumMetricService } from '@services/quantum-metric.service';
import { TooltipService } from '@services/tooltip.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact-us-form',
  templateUrl: './contact-us-form.component.html',
  styleUrls: ['./contact-us-form.component.scss']
})
export class ContactUsFormComponent implements OnChanges, OnInit {

  @Input()
  subject = 'SwiftTerm Contact Form Submission';

  @Input()
  isAuthenticated = false;

  @Input()
  displayAllFields = false;

  @Input()
  customer;

  @Input()
  email;

  @Output()
  submitForm = new EventEmitter<ContactFormFields>();

  @Output()
  cancel = new EventEmitter();

  name: string;
  phone: string;
  message: string;
  noticeAtCollectionLink: string;
  quantumSessionId: string = ''
  quantumSessionIdTooltip: TippyInstance;

  // honeypot fields
  country = '';
  occupation = 'Sales';
  claimsCallToAction = environment.claimsContact;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: {
      customer: Customer,
      email: string,
    },
    private quantumMetricService: QuantumMetricService,
    private tooltipService: TooltipService
  ) {
    this.noticeAtCollectionLink = 'https://www.symetra.com/admin-pages/ccpa-at-collection/'

    if (data?.customer?.firstName && data?.customer?.lastName && data?.customer?.middleName && data?.customer?.suffix) {
      this.name = `${data?.customer?.firstName} ${data?.customer?.middleName} ${data?.customer?.lastName} ${data?.customer?.suffix}`;
    } else if (data?.customer?.firstName && data?.customer?.lastName && !data?.customer?.middleName && !data?.customer?.suffix) {
      this.name = `${data?.customer?.firstName} ${data?.customer?.lastName}`;
    } else if (data?.customer?.firstName && data?.customer?.lastName && data?.customer?.middleName && !data?.customer?.suffix) {
      this.name = `${data?.customer?.firstName} ${data?.customer?.middleName} ${data?.customer?.lastName}`;
    } else if (data?.customer?.firstName && data?.customer?.lastName && !data?.customer?.middleName && data?.customer?.suffix) {
      this.name = `${data?.customer?.firstName} ${data?.customer?.lastName} ${data?.customer?.suffix}`;
    }
    else {
      this.name = '';
    }
    this.email = data?.email || '';
  }

  ngOnInit(): void {
    this.quantumMetricService.getSessionId()
      .subscribe((sessionId) => {
        this.quantumSessionId = sessionId
        this.showHelpIconTooltip();
    })
  }

  isValid(): boolean {
    if (this.isAuthenticated) {
      return !!this.message;
    } else {
      return (
        !!this.name &&
        !!this.email &&
        !!this.phone &&
        !!this.message
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.customer?.currentValue) {
      this.name = `${changes?.customer?.currentValue?.firstName} ${changes?.customer?.currentValue?.lastName}`;
    }
  }

  handleFormSubmit() {
    if (this.isValid()) {
      this.submitForm.emit({
        name: this.name,
        email: this.email,
        phone: this.phone,
        subject: this.subject,
        description: this.message,
        honeypot: this.country,
        reverseHoneypot: this.occupation,
        quantumSessionId: this.quantumSessionId
      });
    }
  }

  showHelpIconTooltip(): void {
    const html = 
    `
      <div style="margin-bottom: 4px; color: white">
          We use your support ID to provide faster resolution
          to any issues experienced on our website.
          </br>
          </br>
          Please provide this ID when contacting support via phone, email or chat
      </div>
    `
    this.quantumSessionIdTooltip = this.tooltipService.showTooltip(
    '#quantum-session-id-tooltip',
    html,
    {
      allowHTML: true,
      delay: [0, 1000],
      interactive: true,
      theme: 'gray-theme',
      placement: 'right',
    });
  }

}

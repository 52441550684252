import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { PolicyService } from '@services/api/policy.service';
import { Policy } from '@services/api/types';
import { unmask } from '@utils/policy-id-obfuscator';

@Injectable({
  providedIn: 'root'
})
export class PolicyResolverService implements Resolve<Policy> {

  constructor(private policyService: PolicyService) { }

  resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    const policyId = unmask(route.paramMap.get('policyId'));
    return this.policyService.getPolicy(policyId).pipe(
      catchError((_error) => {
        return EMPTY;
      })
    );
  }
}

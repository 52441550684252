import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { ApiService } from './api.service';

export interface AmountDueResponse {
  totalAmountDue: number
}

@Injectable({
  providedIn: 'root'
})
export class PaymentMicroService extends ApiService { 

  protected baseUrl: string = environment.paymentServiceBaseApiUrl;

  constructor(protected http: HttpClient) {
    super(http);
  }

  getTotalAmountDue(policyId: string): Observable<number> {
    return this.get<AmountDueResponse>(`/payment-service/v1/${policyId}/invoices/past-due-amount`)
      .pipe(
        map((result: any) => {
          return result.totalAmountDue / 100;
        })
      )
  }

  payPastDueInvoices(policyId: string): Observable<any> {
    return this.post(`/payment-service/v1/${policyId}/invoices/pay`, {});
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { SYASharedModule } from '@swiftcore/ui-shared';
import { NgxMaskModule } from 'ngx-mask';
import { QuillModule } from 'ngx-quill';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { ListItemComponent } from 'src/app/components/list-item/list-item.component';
import { RadioSelectionComponent } from 'src/app/components/radio-selection/radio-selection.component';
import { RoutingNumberInputComponent } from 'src/app/components/routing-number-input/routing-number-input.component';
import { OrdinalSuffixPipe } from 'src/app/pipes/ordinal.pipe';

import { AddressFormComponent } from './address-form/address-form.component';
import { BannerComponent } from './banner/banner.component';
import { ConfirmationTextDialogComponent } from './confirmation-text-dialog/confirmation-text-dialog.component';
import { ContactUsDialogComponent } from './contact-us-dialog/contact-us-dialog.component';
import { ContactUsFormComponent } from './contact-us-form/contact-us-form.component';
import { DownloadButtonComponent } from './download-button/download-button.component';
import { EpiserverContentComponent } from './episerver-content/episerver-content.component';
import { FactSheetContentComponent } from './fact-sheet-content/fact-sheet-content.component';
import { LegalPageWrapperComponent } from './legal-page-wrapper/legal-page-wrapper.component';
import { LoadingComponent } from './loading/loading.component';
import { MilestoneWheelMobileComponent } from './milestone-wheel/milestone-wheel-mobile/milestone-wheel-mobile.component';
import { MilestoneWheelComponent } from './milestone-wheel/milestone-wheel.component';
import { NoPoliciesFoundComponent } from './no-policies-found/no-policies-found.component';
import { OnboardingLoginFormComponent } from './onboarding-login-form/onboarding-login-form.component';
import { PaymentMethodFormComponent } from './payment-method-form/payment-method-form.component';
import { PaymentScheduleFormComponent } from './payment-schedule-form/payment-schedule-form.component';
import { ProjectedRadioSelectionComponent } from './projected-radio-selection/projected-radio-selection.component';
import { PromptDialogComponent } from './prompt-dialog/prompt-dialog.component';
import { StripeElementComponent } from './stripe-element/stripe-element.component';
import { ToggleInputComponent } from './toggle-input/toggle-input.component';

@NgModule({
  declarations: [
    RoutingNumberInputComponent,
    ConfirmationTextDialogComponent,
    OrdinalSuffixPipe,
    PaymentScheduleFormComponent,
    RadioSelectionComponent,
    PaymentMethodFormComponent,
    ToggleInputComponent,
    OnboardingLoginFormComponent,
    EpiserverContentComponent,
    ProjectedRadioSelectionComponent,
    BannerComponent,
    ListItemComponent,
    ContactUsFormComponent,
    ContactUsDialogComponent,
    LegalPageWrapperComponent,
    FactSheetContentComponent,
    AddressFormComponent,
    NoPoliciesFoundComponent,
    MilestoneWheelComponent,
    MilestoneWheelMobileComponent,
    PromptDialogComponent,
    DownloadButtonComponent,
    LoadingComponent,
    StripeElementComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    SYASharedModule,
    QuillModule,
    MatIconModule,
    RouterModule,
    NgxMaskModule.forRoot(),
    NgxSkeletonLoaderModule,
    MatDialogModule
  ],
  exports: [
    RoutingNumberInputComponent,
    ConfirmationTextDialogComponent,
    OrdinalSuffixPipe,
    PaymentScheduleFormComponent,
    RadioSelectionComponent,
    PaymentMethodFormComponent,
    ToggleInputComponent,
    OnboardingLoginFormComponent,
    EpiserverContentComponent,
    ProjectedRadioSelectionComponent,
    BannerComponent,
    ListItemComponent,
    LoadingComponent,
    ContactUsFormComponent,
    ContactUsDialogComponent,
    LegalPageWrapperComponent,
    FactSheetContentComponent,
    NgxSkeletonLoaderModule,
    AddressFormComponent,
    NoPoliciesFoundComponent,
    MilestoneWheelComponent,
    PromptDialogComponent,
    DownloadButtonComponent,
    StripeElementComponent
  ]
})
export class SharedComponentsModule { }

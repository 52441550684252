<img
  src="assets/background-swoop.svg"
  class="background"
  fxHide
  fxShow.gt-md
/>
<img
  src="assets/background-swoop-mobile.svg"
  class="background"
  fxShow
  fxHide.gt-md
/>


<div class="footer-content">
  <div class="contact-info">
    <quill-view
      [content]="footerData?.content?.expandedValue[2]?.content?.value?.ops"
      format="object"
      >
    </quill-view>
  </div>

  <div
    *ngIf="showSocialLinks"
    class="social-links"
  >
    <a
      *ngFor="let social of footerData?.socialLinks?.value"
      [href]="social.href"
      [title]="social.title"
      target="_blank"
      class="social-link"
    >
      <img
        *ngIf="social.text === 'Twitter'"
        src="assets/twitter-logo.svg"
      />

      <img
        *ngIf="social.text === 'Facebook'"
        src="assets/facebook-logo.svg"
      />

      <img
        *ngIf="social.text === 'LinkedIn'"
        src="assets/linkedin-logo.svg"
      />

      <img
        *ngIf="social.text === 'Instagram'"
        src="assets/instagram-logo.svg"
      />
    </a>
  </div>

  <div class="legal-links">
    <ng-container *ngFor="let link of footerData?.content?.expandedValue[1]?.links.value; let lastLink = last;">
      <a [href]="link.href" class="legal-link">{{link.text}}</a> <span *ngIf="!lastLink">|</span>
    </ng-container>
  </div>

  <div class="footer-text">
      <quill-view
        [content]="footerData?.disclaimerText?.value?.ops"
        format="object">
      </quill-view>
  </div>
</div>
<div class="build-id">Build: {{buildId}}</div>

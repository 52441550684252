import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { environment } from 'src/environments/environment';

import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private auth: AuthService,
  ) { }

  canActivate(): boolean {
    if (!this.auth.isAuthenticated()) {
      window.location.href = environment.loginUrl;
    }
    return true;
  }
}

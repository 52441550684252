import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

declare type RequestOptions = {
  headers?: HttpHeaders | {
    [header: string]: string | string[];
  };
  // This Union Type (and responseType below) of strings fails despite being how Angular defines it.
  // Maybe TS version differences?
  observe?: any; // "body" | "events" | "response";
  params?: HttpParams | {
    [param: string]: string | string[];
  };
  reportProgress?: boolean;
  responseType?: any; // 'arraybuffer' | 'blob' | 'json' | 'text';
  withCredentials?: boolean;
};

@Injectable({
  providedIn: 'root'
})
export abstract class ApiService {

  protected baseUrl = environment.baseApiUrl;
  constructor(protected http: HttpClient) { }

  protected get<T = object>(path: string, options: RequestOptions = {}) {
    return this.http.get<T>(`${this.baseUrl}${path}`, options);
  }

  protected post<T = object>(path: string, postBody: any, options: RequestOptions = {}) {
    return this.http.post<T>(`${this.baseUrl}${path}`, postBody, options);
  }

  protected put<T = object>(path: string, putBody: any, options: RequestOptions = {}) {
    return this.http.put<T>(`${this.baseUrl}${path}`, putBody, options);
  }

  protected delete<T = object>(path: string, options: RequestOptions = {}) {
    return this.http.delete<T>(`${this.baseUrl}${path}`, options);
  }

}

<div>
  <div class="profile-details-container">
    <div class="profile-details">
      <p class="profile-name" data-pii>{{name}}</p>
      <p class="profile-email" data-pii>{{email}}</p>
    </div>
  </div>
  <a [routerLink]="'/dashboard'" class="menu-button">My account</a>
  <a [href]="logoutUrl" class="menu-button">Sign out</a>
</div>

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

// https://www.figma.com/design/uGI2cM9I9FwjPL5nw3X0z1/Symetra-Design-System?node-id=29659-4469&t=te0uJ54BLYErYuAi-4
// Basing notification types based on our figma design system
export enum BannerNotificationType {
  GENERAL = 'general',
  INFO = 'info',
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
}
export interface BannerNotification {
  type: BannerNotificationType;
  message: string;
  dismissible?: boolean;
}

export enum ToastNotificationType {
  SUCCESS = 'success',
  ERROR = 'error',
}

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  iconTypes = {
    success: 'assets/checkmark-circle.svg',
    error: 'assets/error.svg'
  };

  // May make these dynamic based on use cases
  toastDefaults = {
    duration: 4000,
    type: 'success',
  };

  public toaster$: Subject<any> = new Subject();

  private bannerSubject: BehaviorSubject<BannerNotification> = new BehaviorSubject(null);

  constructor() { }

  /* To invoke a toast message,
  * Import the NotificationsService into your component
  * Utilize the NotificationsService.openToast function
  EG:
    notificationsService.openToast('Message String', 'Action string');
  */
  openToast(
    message: string,
    type: ToastNotificationType = ToastNotificationType.SUCCESS,
    action?: string
  ) {
    const toastOpts = {
      data: {
        message,
        // Action is currently just a non-functional string. Want to know more use-cases before writing logic for it
        action: action || '',
        type,
      },
      ...this.toastDefaults
    };
    this.toaster$.next(toastOpts);
  }

  setBanner(banner: BannerNotification) {
    this.bannerSubject.next(banner);
  }

  getBanner() {
    return this.bannerSubject.asObservable();
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BenefitsService {
  // updating the key values to match with the peril names mentioned in socotra exposures
  benefitData = {
    'Terminal Illness': {
      name: 'Accelerated death benefit for terminal illness',
      description: 'Up to 75% of the policy’s death benefit ($500,000 maximum) can be accessed in advance if the insured is diagnosed as terminally ill with less than 12 months to live.'
    },
    Terminal_Illness: {
      name: 'Accelerated death benefit for terminal illness',
      description: 'Up to 75% of the policy’s death benefit ($500,000 maximum) can be accessed in advance if the insured is diagnosed as terminally ill with less than 12 months to live.'
    },
    ACCEL_TI: {
      name: 'Accelerated death benefit for terminal illness',
      description: 'Up to 75% of the policy’s death benefit ($500,000 maximum) can be accessed in advance if the insured is diagnosed as terminally ill with less than 12 months to live.'
    },

    'Accidental Death Benefit': {
      name: 'Accidental death benefit rider',
      description: 'If the insured’s death is accidental, this rider provides an additional death benefit of three times the policy’s face amount ($250,000 maximum).'
    },
    Accidental_Death_Benefit: {
      name: 'Accidental death benefit rider',
      description: 'If the insured’s death is accidental, this rider provides an additional death benefit of three times the policy’s face amount ($250,000 maximum).'
    },
    ADB: {
      name: 'Accidental death benefit rider',
      description: 'If the insured’s death is accidental, this rider provides an additional death benefit of three times the policy’s face amount ($250,000 maximum).'
    },

    'Children\'s Term Benefit': {
      name: 'Children’s term life insurance rider',
      description: 'This rider provides $1,000 to $10,000 of life insurance coverage on dependent children ages 15 days through 17 years.'
    },
    CTR: {
      name: 'Children’s term life insurance rider',
      description: 'This rider provides $1,000 to $10,000 of life insurance coverage on dependent children ages 15 days through 17 years.'
    },
    Child_Term_Rider: {
      name: 'Children’s term life insurance rider',
      description: 'This rider provides $1,000 to $10,000 of life insurance coverage on dependent children ages 15 days through 17 years.'
    },

    'Waiver of Premium': {
      name: 'Waiver of premium rider',
      description: 'Premium payments will be waived if the insured becomes critically ill, seriously injured, or disabled for a consecutive period of at least 6 months.'
    },
    Waiver_of_Premium: {
      name: 'Waiver of premium rider',
      description: 'Premium payments will be waived if the insured becomes critically ill, seriously injured, or disabled for a consecutive period of at least 6 months.'
    },
    WP: {
      name: 'Waiver of premium rider',
      description: 'Premium payments will be waived if the insured becomes critically ill, seriously injured, or disabled for a consecutive period of at least 6 months.'
    },
    WOP: {
      name: 'Waiver of premium rider',
      description: 'Premium payments will be waived if the insured becomes critically ill, seriously injured, or disabled for a consecutive period of at least 6 months.'
    },
    Conversion_Enhancement_Rider: {
      name: 'Conversion Enhancement Rider',
      description: 'Available for an additional cost, this optional rider allows you to convert to additional Symetra permanent life insurance products and may extend the length of your conversion period.'
    },
    Value_Added_Benefit: {
      name: 'Value Added Benefit',
      description: 'An included benefit that offers additional services or “benefits” designed to help you manage your health and/or financial wellness.'
    }
  };

  constructor() { }

  getBenefitDetails(shortFormName: string) {
    return this.benefitData[shortFormName];
  }
}

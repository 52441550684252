import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private firstName$ = new BehaviorSubject<string>('');
  private lastName$ = new BehaviorSubject<string>('');
  private suffix$ = new BehaviorSubject<string>('');
  constructor() {}

  setFirstName(firstName: string): void {
    this.firstName$.next(firstName);
  }

  getFirstName(): Observable<string> {
    return this.firstName$.asObservable();
  }

  setLastName(lastName: string): void {
    this.lastName$.next(lastName);
  }

  getLastName(): Observable<string> {
    return this.lastName$.asObservable();
  }

  setSuffixName(suffix: string): void {
    this.suffix$.next(suffix);
  }

  getSuffixName(): Observable<string> {
    return this.suffix$.asObservable();
  }

  getFullName(): Observable<string[]> {
    return combineLatest([
      this.getFirstName(),
      this.getLastName(),
      this.getSuffixName()
    ]);
  }

}

import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-spinner',
  templateUrl: 'spinner.component.html',
  styleUrls: ['spinner.component.scss'],
})
export class AppSpinnerComponent implements OnInit {
  @Input() fullScreen = false
  @Input() spinnerHeight = '5rem'
  @Input() text = 'Please wait...'
  readonly containerClasses: Record<string, any> = {}
  readonly spinnerStyles: Record<string, any> = {}

  ngOnInit() {
    if (this.fullScreen) {
      this.containerClasses.fixed = true
    }

    this.spinnerStyles.height = this.spinnerHeight
  }

}

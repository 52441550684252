import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

export interface ContactFormFields {
  name: string;
  email: string;
  phone: string;
  subject: string;
  description: string;
  honeypot: string;
  reverseHoneypot: string;
  quantumSessionId: string;
}

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient, private document: Document) { }

  sendContactFormAjax(formFields: ContactFormFields) {

    const {
      name,
      email,
      phone,
      subject,
      description,
      honeypot,
      reverseHoneypot
    } = formFields;

    const {
      salesforceDebug,
      salesforceOrgId,
      salesforceDebugEmail,
      contactFormEndpoint,
      contactFormHoneypotFieldKey,
      contactFormReverseHoneypotFieldKey
    } = environment;

    const fields = {
      retURL: 'http://127.0.0.1', // dummy URL
      orgid: salesforceOrgId,
      name,
      email,
      phone,
      subject,
      description,
      [contactFormHoneypotFieldKey]: honeypot,
      [contactFormReverseHoneypotFieldKey]: reverseHoneypot,
    };

    if (salesforceDebug) {
      fields.debug = '1';
      fields.debugEmail = salesforceDebugEmail;
    }

    return this.http.post(contactFormEndpoint, fields);
  }

  sendContactForm(formFields: ContactFormFields) {

    const {
      name,
      email,
      phone,
      subject,
      description,
      honeypot,
      reverseHoneypot,
      quantumSessionId
    } = formFields;

    const {
      salesforceDebug,
      salesforceOrgId,
      salesforceDebugEmail,
      contactFormEndpoint,
      contactFormHoneypotFieldKey,
      contactFormReverseHoneypotFieldKey
    } = environment;

    const fields = {
      retURL: 'http://127.0.0.1', // dummy URL
      orgid: salesforceOrgId,
      name,
      email,
      phone,
      subject,
      description,
      quantumSessionId,
      [contactFormHoneypotFieldKey]: honeypot,
      [contactFormReverseHoneypotFieldKey]: reverseHoneypot,
    };

    if (salesforceDebug) {
      fields.debug = '1';
      fields.debugEmail = salesforceDebugEmail;
    }

    const customHiddenIframeName = 'contact-form-id';
    if (!this.document.getElementById(customHiddenIframeName)) {
      const theIframe = this.document.createElement('iframe');
      theIframe.id = customHiddenIframeName;
      theIframe.name = customHiddenIframeName;
      theIframe.src = 'about:blank';
      theIframe.style.display = 'none';
      this.document.body.appendChild(theIframe);
    }

    const form = this.document.createElement('form');
    form.method = 'POST';
    form.action = contactFormEndpoint;
    form.setAttribute('target', customHiddenIframeName);
    Object.keys(fields).forEach((fieldName) => {
      const theInput = this.document.createElement('input');
      theInput.name = fieldName;
      theInput.value = fields[fieldName];
      theInput.setAttribute('type', 'hidden');
      form.appendChild(theInput);
    });
    this.document.body.appendChild(form);
    form.submit();
  }

}

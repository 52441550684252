import { Component, Input } from '@angular/core';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile-dropdown',
  templateUrl: './profile-dropdown.component.html',
  styleUrls: ['./profile-dropdown.component.scss']
})
export class ProfileDropdownComponent {

  @Input()
  name: string;

  @Input()
  email: string;

  logoutUrl = environment.logoutUrl;

  constructor() { }

}

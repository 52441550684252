import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from 'src/app/services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private AUTH_HEADER = 'AuthorizationToken';
  constructor(private authService: AuthService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    req = req.clone({
      headers: req.headers.set('Accept', 'application/json'),
    });

    req = this.addAuthenticationToken(req);

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        // TODO(davmau): when calling the /onboarding/mfa lambda, it returns a 401 for invalid codes, so we shouldn't treat that as an authentication issue
        if (error?.status === 401 && !req.url.includes("/onboarding/mfa")) {
          this.authService.loginFromInvalidToken();
        } else {
          return throwError(error);
        }
      })
    );
  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    // TODO(davmau): this is broken as authService.isAuthenticated only checks for existence of a token, not that it's valid/unexpired
    if (!this.authService.isAuthenticated()) {
      return request;
    }
    return request.clone({
      headers: request.headers.set(
        this.AUTH_HEADER,
        `Bearer ${this.authService.getToken()}`
      )
    });
  }
}

import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';

import { ApiService } from 'src/app/services/api/api.service';
import { environment } from 'src/environments/environment';

export enum ErrorType {
  HTTP = 'http',
  GLOBAL = 'global'
}

@Injectable({
  providedIn: 'root'
})
export class ErrorService extends ApiService {

  constructor(
    protected http: HttpClient,
    private router: Router,
    private location: Location,
  ) {
    super(http);
  }

  sendErrorToServer(
    type: ErrorType,
    error: Error | string,
    extra?: { [key: string]: any }
  ) {
    const telemetryParam = this.router.routerState.snapshot?.root?.queryParams?.senderrors;
    if (environment.senderrors || telemetryParam === 'true') {
      return this.post('/errors/client', {
        type,
        error,
        url: this.location?.toString(),
        browserDetails: [
          window?.navigator?.platform,
          window?.navigator?.userAgent,
          window?.navigator?.appVersion,
          window?.navigator?.vendor,
        ],
        extra: {
          ...extra,
          buildId: environment.buildId,
        }
      });
    } else {
      return of();
    }
  }

}

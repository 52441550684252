import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  ONBOARDING_CODE_KEY,
  ONBOARDING_CUSTOMER_ID_KEY,
  ONBOARDING_PAYMENT_INTENT_ID_KEY,
  ONBOARDING_PAYMENT_METHOD_KEY,
  ONBOARDING_PAYMENT_SCHEDULE_KEY } from '@utils/constants';
import { PaymentMethod, PaymentScheduleOption, PolicyPreview } from 'src/app/services/api/types';

@Injectable({
  providedIn: 'root'
})
export class OnboardingFormStateService {
  // TODO(davmau): how is policy preview part of the onboarding form state?
  private policyPreview = new BehaviorSubject<PolicyPreview>(undefined);

  private paymentSchedule = new BehaviorSubject<PaymentScheduleOption>(undefined);
  private paymentMethod = new BehaviorSubject<PaymentMethod>(undefined);
  private paymentIntentId = new BehaviorSubject<string>(undefined);
  private customerId = new BehaviorSubject<string>(undefined);

  constructor(private window: Window) { }

  // TODO(davmau): rename to getScheduleOption() ?
  //
  getSchedule(): Observable<PaymentScheduleOption> {
    return this.paymentSchedule.asObservable().pipe(
      map(paymentSchedule => {
        // this condition only works because the subject is specifically defaulted to undefined
        if (!paymentSchedule) {
          let storedSchedule: any = this.window.localStorage.getItem(ONBOARDING_PAYMENT_SCHEDULE_KEY);
          if (storedSchedule) {
            storedSchedule = JSON.parse(storedSchedule);
          }
          return storedSchedule as PaymentScheduleOption;
        } else {
          return paymentSchedule;
        }
      })
    );
  }

  setSelectedScheduleOption(schedule: PaymentScheduleOption) {
    this.window.localStorage.setItem(ONBOARDING_PAYMENT_SCHEDULE_KEY, JSON.stringify(schedule));
    this.paymentSchedule.next(schedule);
  }

  // TODO(davmau): remove once migrated to setSelectedScheduleOption()
  setSchedule(option: PaymentScheduleOption) {
    this.window.localStorage.setItem(ONBOARDING_PAYMENT_SCHEDULE_KEY, JSON.stringify(option));
    this.paymentSchedule.next(option);
  }

  getPaymentMethod(): Observable<PaymentMethod> {
    return this.paymentMethod.asObservable().pipe(
      map(paymentMethod => {
        if (!paymentMethod) {
          let storedMethod: any = this.window.localStorage.getItem(ONBOARDING_PAYMENT_METHOD_KEY);
          if (storedMethod) {
            storedMethod = JSON.parse(storedMethod);
          }
          return storedMethod as PaymentMethod;
        } else {
          return paymentMethod;
        }
      })
    );
  }

  setPaymentMethod(paymentMethod: PaymentMethod) {
    this.window.localStorage.setItem(ONBOARDING_PAYMENT_METHOD_KEY, JSON.stringify(paymentMethod));
    this.paymentMethod.next(paymentMethod);
  }

  getPaymentIntentId() {
    return this.paymentIntentId.asObservable().pipe(
      map(paymentIntentId => {
        if (!paymentIntentId) {
          const storedIntentId = this.window.localStorage.getItem(ONBOARDING_PAYMENT_INTENT_ID_KEY);
          return storedIntentId;
        } else {
          return paymentIntentId;
        }
      })
    );
  }

  setPaymentIntentId(paymentIntentId: string) {
    this.window.localStorage.setItem(ONBOARDING_PAYMENT_INTENT_ID_KEY, paymentIntentId);
    this.paymentIntentId.next(paymentIntentId);
  }

  getCustomerId() {
    return this.customerId.asObservable().pipe(
      map(customerId => {
        if (!customerId) {
          const storedCustomerId = this.window.localStorage.getItem(ONBOARDING_CUSTOMER_ID_KEY);
          return storedCustomerId;
        } else {
          return customerId;
        }
      })
    );
  }

  setCustomerId(customerId: string) {
    this.window.localStorage.setItem(ONBOARDING_CUSTOMER_ID_KEY, customerId);
    this.customerId.next(customerId);
  }

  // TODO(davmau): Shouldn't the policy preview be stored in PolicyService?
  getPolicyPreview() {
    return this.policyPreview.asObservable();
  }

  setPolicyPreview(policyPreview: PolicyPreview) {
    this.policyPreview.next(policyPreview);
  }

  clearFormState() {
    this.window.localStorage.removeItem(ONBOARDING_PAYMENT_METHOD_KEY);
    this.window.localStorage.removeItem(ONBOARDING_PAYMENT_SCHEDULE_KEY);
    this.window.localStorage.removeItem(ONBOARDING_PAYMENT_INTENT_ID_KEY);
    this.window.localStorage.removeItem(ONBOARDING_CUSTOMER_ID_KEY);
    this.window.localStorage.removeItem(ONBOARDING_CODE_KEY);
  }

}

<app-header
></app-header>


<main
  [class.foot-padding]="isAuthenticated"
  [class.remove-min-height]="shouldRemoveMinHeight && isAuthenticated"
  >

  <app-background-handler></app-background-handler>
  <router-outlet></router-outlet>
</main>

<app-footer *ngIf="shouldShowFooter"></app-footer>

<app-spinner
  *ngIf="spinner$ | async"
  [fullScreen]=true
>
</app-spinner>

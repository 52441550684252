import { Injectable } from '@angular/core';

import EpiserverData from 'src/assets/epi-data.json';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  private data = EpiserverData;

  constructor() {}

  getData() {
    return this.data;
  }

  getPageData(pageKey: string) {
    return this.data[pageKey];
  }

  getArticleData(articleKey: string) {
    return this.data.articles[articleKey];
  }
}

import { Address } from '@api-types/index';

type BeneficiaryTypeUnion =
  | IndividualBeneficiary
  | TrustBeneficiary
  | EstateOfInsuredBeneficiary
  | CharityOrNonProfitBeneficiary
  | BusinessBeneficiary
  | CorporateBeneficiary;

enum BeneficiaryType {
  Individual = 'Person',
  Trust = 'Trust',
  Estate = 'Estate',
  NonProfit = 'Non-Profit',
  Business = 'Business',
  Corporation = 'Corporation',
}

enum RoleCodes {
  Primary = 'Beneficiary_Primary',
  Contingent = 'Beneficiary_Contingent'
}

enum EntityType {
  Individual = 'Individual',
  Organization = 'Organization'
}

enum IndividualBeneficiaryRelationship {
  SpouseOrDomesticPartner = 'Spouse/Domestic Partner',
  Parent = 'Parent',
  Sibling = 'Sibling',
  Child = 'Child',
  NieceOrNephew = 'Niece/Nephew',
  Grandchild = 'Grandchild',
  BusinessPartner = 'Business Partner',
  Other = 'Other',
}

abstract class BeneficiaryBase {
  beneficiaryId: string;
  beneficiaryType: BeneficiaryType;
  primary: boolean;
  percentage: number;
  email: string;
  phone: string;
  address: Address;

  abstract get fullName(): string;
  abstract get relation(): string;
}

class IndividualBeneficiary extends BeneficiaryBase {
  beneficiaryId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  suffix: string;
  relationship: IndividualBeneficiaryRelationship;
  otherRelationValue?: string;
  dateOfBirth: string;
  socialSecurityNumber: string;

  get fullName(): string {
    let fullName = this.firstName;

    if (this.middleName && this.middleName.length) {
      fullName += ' ' + this.middleName;
    }

    fullName += ' ' + this.lastName;

    if (this.suffix && this.suffix.length) {
      fullName += ' ' + this.suffix;
    }
    return fullName;
  }

  get relation(): string {
    if (this.relationship === IndividualBeneficiaryRelationship.Other) {
      return `${this.relationship} - ${this.otherRelationValue}`;
    }

    return this.relationship;
  }

  constructor(config?: Partial<IndividualBeneficiary>) {
    super();
    Object.assign(this, config);
  }
}

class TrustBeneficiary extends BeneficiaryBase {
  nameOfTrustee: string;
  dateOfCreation: string;
  taxIdentificationNumber: string;

  get fullName(): string {
    return this.nameOfTrustee;
  }

  set fullName(name: string) {
    this.nameOfTrustee = name;
  }

  get relation(): string {
    return this.beneficiaryType;
  }

  constructor(config?: Partial<TrustBeneficiary>) {
    super();
    Object.assign(this, config);
  }
}

class EstateOfInsuredBeneficiary extends BeneficiaryBase {
  nameOfEstate: string;

  get fullName(): string {
    return this.nameOfEstate;
  }

  set fullName(name: string) {
    this.nameOfEstate = name;
  }

  get relation(): string {
    return this.beneficiaryType;
  }

  constructor(config?: Partial<EstateOfInsuredBeneficiary>) {
    super();
    Object.assign(this, config);
  }
}

class CharityOrNonProfitBeneficiary extends BeneficiaryBase {
  nameOfCharityOrNonProfit: string;
  taxIdentificationNumber: string;

  get fullName(): string {
    return this.nameOfCharityOrNonProfit;
  }

  set fullName(name: string) {
    this.nameOfCharityOrNonProfit = name;
  }

  get relation(): string {
    return this.beneficiaryType;
  }

  constructor(config?: Partial<CharityOrNonProfitBeneficiary>) {
    super();
    Object.assign(this, config);
  }
}

class BusinessBeneficiary extends BeneficiaryBase {
  nameOfBusiness: string;
  taxIdentificationNumber: string;

  get fullName(): string {
    return this.nameOfBusiness;
  }

  set fullName(name: string) {
    this.nameOfBusiness = name;
  }

  get relation(): string {
    return this.beneficiaryType;
  }

  constructor(config?: Partial<BusinessBeneficiary>) {
    super();
    Object.assign(this, config);
  }
}

class CorporateBeneficiary extends BeneficiaryBase {
  nameOfCorporation: string;
  taxIdentificationNumber: string;

  get fullName(): string {
    return this.nameOfCorporation;
  }

  set fullName(name: string) {
    this.nameOfCorporation = name;
  }

  get relation(): string {
    return this.beneficiaryType;
  }

  constructor(config?: Partial<CorporateBeneficiary>) {
    super();
    Object.assign(this, config);
  }
}

function isBeneficiaryRelationshipEnum(
  token: any
): token is IndividualBeneficiaryRelationship {
  return Object.values(IndividualBeneficiaryRelationship).includes(
    token as IndividualBeneficiaryRelationship
  );
}

const BeneficiaryTypeMap = {
  [BeneficiaryType.Individual]: IndividualBeneficiary,
  [BeneficiaryType.Trust]: TrustBeneficiary,
  [BeneficiaryType.Estate]: EstateOfInsuredBeneficiary,
  [BeneficiaryType.NonProfit]: CharityOrNonProfitBeneficiary,
  [BeneficiaryType.Business]: BusinessBeneficiary,
  [BeneficiaryType.Corporation]: CorporateBeneficiary
};

export {
  BeneficiaryBase,
  BeneficiaryType,
  BeneficiaryTypeMap,
  BeneficiaryTypeUnion,
  BusinessBeneficiary,
  CorporateBeneficiary,
  CharityOrNonProfitBeneficiary,
  EntityType,
  EstateOfInsuredBeneficiary,
  IndividualBeneficiary,
  IndividualBeneficiaryRelationship,
  isBeneficiaryRelationshipEnum,
  RoleCodes,
  TrustBeneficiary 
};

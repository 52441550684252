import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'

import { AppSpinnerComponent } from './spinner.component'

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
  ],
  declarations: [AppSpinnerComponent],
  exports: [AppSpinnerComponent]
})
export class AppSpinnerModule { }

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, interval } from 'rxjs';
import { tap, take, map, skipWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class QuantumMetricService {
  private quantumSessionId$: BehaviorSubject<string> = new BehaviorSubject('')
  constructor(
    private window: Window
  ) { }
  createSessionId(): Observable<any> {
    return interval(1000).pipe(
      take(10),
      map(() => this.getSessionIdFromAPI()),
      tap(sessionId => this.quantumSessionId$.next(sessionId)),
      skipWhile(sessionId => sessionId == null),
      take(1),
    )
  }
  getSessionId(): Observable<string> {
    return this.quantumSessionId$.asObservable()
  }
  getSessionIdFromAPI(): string {
    return (this.window as any)?.QuantumMetricAPI?.getSession()?.slice(0, 8)
  }
}
import { ErrorHandler, Injectable } from '@angular/core';

import { ErrorService, ErrorType } from 'src/app/services/api/error.service';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {

  constructor(private errorService: ErrorService) { }

  handleError(error) {
    // do something with the exception
    console.error('GLOBAL ERROR HANDLER', error);
    this.errorService.sendErrorToServer(ErrorType.GLOBAL, error).subscribe();
  }
}

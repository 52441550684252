import { Component, HostBinding, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { GoogleTagManagerService } from 'ngx-google-tag-manager';

import { AppSpinnerService } from '@services/app-spinner.service'
import { QuantumMetricService } from '@services/quantum-metric.service';
import { ToasterComponent } from 'src/app/components/toaster/toaster.component';
import { environment } from 'src/environments/environment';

import { AuthService } from './services/auth.service';
import { NotificationsService } from './services/notifications.service';

const noMinHeightRequirementRoutes = [
  '/contact-us'
];

const appRoutes = [
  '/dashboard',
  '/onboarding',
  '/contact-us'
];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'SwiftTerm';

  shouldShowAppBackground = false;
  shouldShowContractBackground = false;
  shouldRemoveMinHeight = false;
  spinner$ = this.spinnerService.state$
  shouldShowFooter = true;

  @HostBinding('class.app-bg')
  get appBackground() {
    return this.shouldShowAppBackground;
  }

  constructor(
    private readonly spinnerService: AppSpinnerService,
    private router: Router,
    private auth: AuthService,
    private notificationsService: NotificationsService,
    private toaster: MatSnackBar,
    private gtmService: GoogleTagManagerService,
    private quantumMetricService: QuantumMetricService
  ) {}

  ngOnInit() {
    this.quantumMetricService.createSessionId().subscribe()
    // Try to load token from storage
    this.auth.getToken();

    if (typeof window !== 'undefined') {
      this.router.events.subscribe(this.handleNavigationEvent.bind(this));
    }

    this.notificationsService.toaster$.subscribe((options) => {
      this.toaster.openFromComponent(ToasterComponent, options);
    });
  }

  handleNavigationEvent(event: RouterEvent) {
    if (event instanceof NavigationEnd) {
      const { googleTagManagerID } = environment;
      if (googleTagManagerID !== '') {
        this.gtmService.pushTag({
          event: 'page',
          pageName: event.url
        });
      }

      this.shouldShowAppBackground = false;
      appRoutes.forEach(prefix => {
        if (event.url.indexOf(prefix) > -1) {
          this.shouldShowAppBackground = true;
        }
      });

      this.shouldRemoveMinHeight = false;
      noMinHeightRequirementRoutes.forEach(prefix => {
        if (event.url.indexOf(prefix) > -1) {
          this.shouldRemoveMinHeight = true;
        }
      });

      this.shouldShowFooter = true;
      if (event.url.indexOf('/contract') > -1) {
        this.shouldShowFooter = false;
      }
    }
  }

  isAuthenticated() {
    return this.auth.isAuthenticated();
  }
}

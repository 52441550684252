import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';

import { ErrorService, ErrorType } from 'src/app/services/api/error.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private errorService: ErrorService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let modifiedRequest = request.clone();
    let operator: any = retry(1);

    if (request.headers.has('InterceptorSkipHeader')) {
      const headers = request.headers.delete('InterceptorSkipHeader');
      modifiedRequest = request.clone({ headers });
      operator = map(response => response);
    }

    return next.handle(modifiedRequest)
      .pipe(
        // we could consider more retries
        operator,
        catchError((error: HttpErrorResponse) => {

          if (request?.url?.includes('/errors/client')) {
            console.error('Error sending user error data to server.', error);
            return;
          }

          let errorMessage = '';

          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `HTTP Client Error: ${error.error.message}`;
          } else {
            // server-side error
            errorMessage = `HTTP Server Error Code: ${error.status}\nMessage: ${error.message}`;
          }

          // All errors for now. We might consider separating into separate ErrorTypes
          this.errorService.sendErrorToServer(ErrorType.HTTP, errorMessage, {
            headers: request.headers.keys().map(key => {
              return {
                [key]: request.headers.get(key)
              };
            }),
          });

          return throwError({
            ...error,
            customError: errorMessage
          });
        })
      );
  }
}
